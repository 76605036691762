<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t('fake_gps.title') }}</h1>
      </div>
      <v-breadcrumbs-item class="breadcrumbs-hover" v-if="$route.name === 'employee_ot.edit'">
        <span v-show="true">/</span>
        <span>{{ $t('Employee.search') }}</span>
      </v-breadcrumbs-item>
    </div>
    <hr />
    <br />
    <div class="section-action">
      <v-row class="mb-8">
        <v-col cols="4" md="4" lg="4">
          <label class="label-input">{{ $t('Employee.search_filter') }}</label>
          <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense v-model="filters.search"
            prepend-inner-icon="mdi-magnify" :placeholder="$t('Search.search')" @keypress.enter="searchFilterItem">
          </v-text-field>
        </v-col>
        <v-col cols="12" md="2" lg="2">
          <label class="label-input">{{ $t('groupSchedule.roster.rosterOption.date.title') }}</label>
          <DatePicker class="date-time" @keydown.enter="fetchEmployeeFakeGps" style="width: 100%" type="date"
            valueType="format" slot="activator" :append-to-body="true" v-model="filters.date" requried></DatePicker>
        </v-col>

      </v-row>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listData.length > 0">
          <thead style="border: 1px solid #d3d3d3">
            <tr>
              <th class="text-left">No</th>
              <th>ວັນທີ່</th>
              <th>ລະຫັດພະນັກງານ</th>
              <th>ຊື່ພະນັກງານ</th>
              <th>ກະວຽກ</th>
              <th>ຈຸດສະແກນ</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in listData" :key="idx">
              <td>{{ idx + 1 }}</td>
              <td>{{ item.date }}</td>
              <td>{{ item.emp_number }}</td>
              <td>{{ item.name }} {{ item.surname }}</td>
              <td>{{ item.schedule_name }}</td>
              <td>
                <a v-bind:href="`https://www.google.com/maps/search/?api=1&query=${item.longitude},${item.latitude}`
                  " target="_blank">
                  <span>
                    <i class="fas fa-map-marker"></i>
                  </span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else />
        <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
          @paginate="fetchEmployeeFakeGps">
        </Pagination>
        <Loading v-if="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Pagination from "../../components/Paginate/Pagination";
import defaultTableNoResult from "@/components/defaultTableNoResult";
import Loading from "@/components/Loading";

import "vue2-datepicker/index.css";
export default {
  components: {
    DatePicker,
    Pagination,
    defaultTableNoResult,
    Loading,
  },
  data() {
    return {
      offset: 10,
      pagination: {
        current_page: 1
      },
      employeeOt: {},
      isLoading: true,
      listData: [],
      isLoading: true,
      page: 1,
      per_page: 10,
      filters: {
        date: "",
        search: "",
      },
    };
  },
  methods: {

    searchFilterItem() {
      this.fetchEmployeeFakeGps();
      this.pagination.current_page = 1;
    },

    fetchEmployeeFakeGps() {
      this.isLoading = true;
      this.$axios
        .get(`company/report/employee-fake-gps`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            date: this.filters.date,
            search: this.filters.search,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.listData = res.data.data.data;
            this.pagination = res.data.data.paginate;
            if (!this.listData.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchEmployeeFakeGps();
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 20px;
}
</style>
